@import "./partials/variable";
@import "./partials/style-guide";
@import "./partials/fonts";
// datetimepicker workaround
@import "./partials/datetimepicker";

// body {
//   background-color: #f4f3f9;
// }
// .mat-drawer-content .mat-sidenav-content{
// background-color: $body-color;
// }
.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-6 {
  margin-top: 60px;
}

.mt-7 {
  margin-top: 70px;
}

.ml-1 {
  margin-left: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.font-bold {
  font-weight: 500;
}
.f-w-b{
  font-weight: 500;
}
.space-right {
  margin-right: 10px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.display-block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.posR {
  position: relative;
}

.cursor-point {
  cursor: pointer;
}

.no-cursor {
  cursor: none;
}

.no-margin {
  margin: 0;
}

input {
  font-family: "Helventica";
}

input[readonly="true"] {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]~.mdc-checkbox__background {
  background-color: #d0d0d0 !important;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: $button-primary-color;
  font-family: "Larssiet-medium";
}

.mat-mdc-card {
  padding: 30px;
}

.user-related-information-card .mat-mdc-card mat-card-header {
  padding: 15px 15px;
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: $button-primary-color;
  font-family: "Larssiet-medium";
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: #fbc197 !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #fd6b00 !important;
}

.btn-space {
  padding: 0 20px;
  margin-top: 25px;
}

.user-img-holder {
  width: 55px;
  height: 55px;
  position: relative;
  border-radius: 50%;
  background-color: #d9d6e3;

  .user {
    line-height: 55px;
    text-align: center;
    display: none;

    &.user-selected {
      display: block;

      +.img-alt,
      +.img-replacer {
        display: none;
      }
    }
  }

  &.hover-effect:hover {

    .img-alt,
    .img-replacer {
      display: none;
    }

    .user {
      display: block;
    }
  }

  .img-alt {
    font-size: 20px;
    color: $button-accent-color-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .img-replacer {
    width: 100%;
    height: 100%;
    // object-fit: none;
    border-radius: 50%;
  }
}

tr.table-row {
  height: 65px !important;
}

tr.users {
  height: 90px !important;
}

button.heading-button {
  min-width: 120px;
}

.user-related-information-card {
  padding: 10px 40px 10px 40px;

  mat-card-header {
    background: $brand-accent-color;
    padding: 15px 0;
    color: $white-color;
    border-radius: 10px 10px 0px 0px;
  }

  ::ng-deep .mat-card-header-text {
    width: 100%;
    text-align: center;
  }

  mat-card-content {
    padding: 10px;
  }

  mat-card-title {
    margin: 0 !important;
    font-family: "Larssiet-medium";
  }

  mat-card {
    padding: 0;
    border-radius: 10px;
    width: 300px;
  }

  .info-column {
    padding: 15px 20px 10px 20px;

    h5 {
      color: $brand-accent-color;
      font-size: 16px;
      font-family: "Helventica-Bold";
    }

    p {
      font-family: "Helventica";
    }
  }
}

.search-box {
  input {
    width: 100%;
    height: 41px;
    padding: 0;
    border-radius: 5px;
    font-family: "Helventica";
    font-size: 18px;
    outline: none;
    background-color: $white-color;
    border: 1px solid $white-color;
    text-indent: 15px;
    box-shadow: 0px 0px 5.34px 0.66px rgb(0 0 0 / 27%);

    &::placeholder {
      color: $grey-input-lighter;
    }
  }

  .search-icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
    height: 20px;
    width: 20px;
  }
 .search-icon:hover {
  fill: red; /* Change the SVG icon color on hover */
  transform: scale(1.1); /* Slightly enlarge the icon */
 
}

.search-icon:hover svg path {
  fill: $button-primary-color!important;
  transition: fill 0.3s ease;
}
}

.mat-toolbar.dialog-top-bar {
  background-image: linear-gradient(-86deg, #2d2d2d 0%, #5f5f61 100%);
}

.title {
  color: $white-color;
  font-size: 22px;
  font-family: "Larssiet-medium";

  &.title-small {
    font-size: 18px;
  }
}

.custom-dialog {
  &.border-radius {
    .mat-mdc-dialog-container {
      border-radius: 10px;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.16));

      // .dialog-top-bar {
      //   border-radius: 10px 10px 0 0;
      // }
      dialog-top-bar {
        border-radius: 0px;
      }

      .content-space {
        padding: 30px 15px;

        .heading {
          font-size: 18px;
          line-height: 30px;
          color: $grey-black;
          font-family: "Larssiet-medium";
        }
      }
    }
  }

  .mat-mdc-dialog-container {
    padding: 0;
    margin: 0;

    .logo {
      margin: 0 10px 10px 0;
    }

    .content-section {
      margin: 40px;
    }

    .btn-lg {
      width: 187px;
      height: 52px;
      border-radius: 5px;
      filter: drop-shadow(0 0 2.5px rgba(0, 0, 0, 0.2));
    }
  }
}

.enable-cta {
  label {
    color: #fd6b00;
    font-weight: 500!important;
  }
}

.add-custom-dialog {
  // margin-top: 50px;
  margin: 50px;

  .mat-mdc-dialog-container {
    padding: 0px !important;
  }
}

.custom-dialog {
  margin: 50px;
}

.add-system-segment-custom-dialog {
  margin: 50px;

  // margin-top: 50px;
  .mat-dialog-container {
    padding: 0px !important;
  }
}

.mat-mdc-dialog-content.dialog-content-div {
  max-height: initial;
  height: 100%;
}


.custom-chip {
  width: 234px;
  height: 50px;
  border-radius: 20px;
  background-color: $white-color;
  border: 1px solid $button-primary-color;
  display: inline-block;

  .text-content {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    line-height: 30px;
    color: $button-primary-color;
    font-family: "Larssiet";
  }
}

.no-style {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

.list-container {
  padding: 30px;
  //min-height: 100vh;
// overflow-y: scroll;
overflow: hidden;
}

.custom-table {
  &.mat-table {
    border-radius: 10px;
    overflow: hidden !important;

    thead {
      background-color: $light-blue;
      font-family: "Larssiet-medium";

      .mat-header-cell {
        font-size: 16px;
      }
    }

    tr.mat-row {
      font-family: "Larssiet";

      td {
        font-size: 16px;
        color: $grey-dark;
      }
    }

    tr.mat-row:hover {
      background-color: $light-pink;
    }

    thead,
    tr.mat-row:hover {
      filter: drop-shadow(0 0 2.5px rgba(0, 0, 0, 0.21));
    }
  }
}

.color-orange-tag {
  color: $button-primary-color;
  font-weight: 500;
}

.secondary-data {
  margin-top: 5px;
}

.action-button-group {
  margin-top: 40px;

  button {
    min-width: 120px;
  }
}

.assign-name {
  font-size: 16px;
  color: $brand-accent-color;
  font-family: "Larssiet-medium";
}

.description {
  font-size: 16px;
  color: $grey-dark;
  font-family: "Larssiet";
}

.section-header {
  .header-right-section {
    &.white {
      background-color: $white-color;

      +.button-section {
        background-color: $white-color;
      }
    }

    &:not(.no-bg):not(.white) {
      //background-color: #f5f3f9;
      //background-color: $body-color;
      background-color: #f5f2f9 !important;
    }
  }
}

// sidenav
.sidenav-bar {
  height: 100%;

  &.customer_activity {
    .mat-drawer-inner-container {

      overflow: hidden;

    }
  }

  &.timeFilterSidenav {
    .mat-drawer-inner-container {

      overflow: auto;

    }
  }

  .profile-padding {
    padding: 20px;
    border-bottom: 2px solid #e2e2e2;
  }

  mat-sidenav {
    max-width: 394px;
    width: 100%;

    .title-header {
      margin: 20px;
    }

    .mat-drawer-inner-container {
      position: relative;
    }

    .sidebar-list-view {
      height: calc(100% - 206px);
      overflow-y: scroll;

      .list-item {
        padding: 0 20px;

        &.top-padding {
          padding: 5px 20px;
        }

        &.top-padding-no-img {
          padding: 15px 20px;
        }

        &.active {
          .tick-icon svg path {
            fill: $green-color;
          }
        }

        &.active,
        &:hover {
          background-color: $light-pink;
        }

        border-bottom: 2px solid $border-light-grey;

        .serial-no {
          font-size: 14px;
          // line-height: 30px;
          color: #8e8e8e;
        }
      }
    }

    .bottom-fixed {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;
    }
  }
}

.note,
.secondary-section {
  color: $grey-lighter;
  font-family: "Helventica";
}

.note {
  font-size: 18px;
}

.secondary-section {
  font-size: 15px;
}

.tabs-wrapper {
  padding: 20px 5px;

  .tabs-group {
    .tab-container {
      padding: 10px;
    }
  }
}

.mat-tab-group.mat-warn {
  .mat-ink-bar {
    background-color: $button-primary-color;
  }

  .mat-tab-label {
    color: black;
    font-family: "Larssiet";
    font-size: 16px;

    &.mat-tab-label-active {
      color: $button-primary-color;
    }
  }
}

.mat-drawer-container {
  background-color: $body-color!important;
}

/*ellipsis classes*/
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.ellipsis-335 {
  @extend .ellipsis;
  max-width: 335px;
}

mat-icon.warning svg path {
  fill: $button-primary-color;
}


.date-label,
.input,
.mat-select,
.mat-radio-label-content {
  font-family: "Larssiet";
}

.blur-effect {
  opacity: 0.3;
}

.mat-tab-group.mat-warn .mat-tab-label.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.status {

  &.active,
  &.cleared {
    color: $green-color;font-weight: 500;
  }

  &.suspended,
  &.suspend,
  &.failed {
    color: red;font-weight: 500;
  }

  &.warning {
    color: #d63135;font-weight: 500;
  }

  &.critical {
    color: #a12a49;font-weight: 500;
  }

  &.caution {
    color: #e7913a;font-weight: 500;
  }

  &.alert {
    color: #e7913a;font-weight: 500;
  }

  &.normal {
    color: #56a64f;font-weight: 500;
  }

  &.unknown,
  &.unclear {
    color: #a0a0a0;font-weight: 500;
  }

  &.forced_cleared {
    color: #3c78d8;font-weight: 500;
  }

  &.activity {
    color: #067C00;font-weight: 500;
  }

  &.health {
    color: #66DB25;font-weight: 500;
  }

  &.operational {
    color: #2D93FA;font-weight: 500;
  }

  &.statistic {
    color: #556F7B;font-weight: 500;
  }

  &.system_mode {
    color: #FF9800;font-weight: 500;
  }

  &.error {
    color: #E91E63;font-weight: 500;
  }

  &.software_update {
    color: #a64d79;font-weight: 500;
  }

  &.config_update {
    color: #45818e;font-weight: 500;
  }

  &.system_info {
    color: #6d9eeb;font-weight: 500;
  }

  &.remote_command {
    color: #b45f06;font-weight: 500;
  }

  &.running {
    color: #b45f06;font-weight: 500;
  }

  &.not-running {
    color: #434343 !important;font-weight: 500!important;
  }

  &.completed {
    color: #6aa84f;font-weight: 500;
  }

  &.immediate {
    color: #a64d79;font-weight: 500;
  }

  &.enabled {
    color: #6aa84f;font-weight: 500;
  }

  &.normal {
    color: #6aa84f;font-weight: 500;
  }

  &.high {
    color: #FF9800;font-weight: 500;
  }

  &.disabled {
    color: #434343;font-weight: 500;
  }

  &.in-future {
    color: #3c78d8;font-weight: 500;
  }

  &.inactive {
    color: #434343;font-weight: 500;
  }

  &.in-progress {
    color: #fab963;font-weight: 500;
  }

  &.waiting {
    color: #434343 !important;font-weight: 500!important;
  }

  /* Define CSS classes for different system statuses */
  &.connected {
    color: #067C00;font-weight: 500;
  }

  &.recently_connected {
    color: #66DB25;font-weight: 500;
  }

  &.recently_disconnected {
    color: #2D93FA;font-weight: 500;
  }

  &.disconnected {
    color: #556F7B;font-weight: 500;
  }

  &.offline {
    color: #FF9800;font-weight: 500;
  }

  &.never_connected {
    color: #E91E63;font-weight: 500;
  }

  &.never-used {
    color: #595959;font-weight: 500;
  }

  &.in-used {
    color: #6aa84f;font-weight: 500;
  }

  &.rolled-out {
    color: #E91E63;font-weight: 500;
  }


}

.text-width-ellipsis {
  width: 100%;
  max-width: 400px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.empty-screen {
  h4 {
    color: $brand-primary-color;
    margin: 15px 0;
  }

  p {
    margin-bottom: 15px;
  }

  .empty-screen-img {
    width: 100%;
    max-width: 300px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.status-badge {
  border-radius: 50px;
  padding: 3px 25px;
  font-size: 14px;
  white-space: nowrap;

  &.active {
    border: 1px solid #00a65a;
    color: #00a65a;
    max-width: 65px;
    width: 100%;font-weight: 500;
    display: block;
    text-align: center;
  }
  &.in-progress {
    color: #fab963;font-weight: 500;
    border: 1px solid #fab963;
    max-width: 65px;
    width: 100%;font-weight: 500;
    display: block;
    text-align: center;
  }

  &.waiting {
    border: 1px solid #434343;
    color: #434343;
    max-width: 65px;
    width: 100%;font-weight: 500;
    display: block;
    text-align: center;
  }
  &.completed {
    border: 1px solid #6aa84f;
    color: #6aa84f;
    max-width: 65px;
    width: 100%;font-weight: 500;
    display: block;
    text-align: center;
  }

  &.never-used {
    border: 1px solid #595959;
    color: #595959;
    max-width: 65px;font-weight: 500;
    width: 100%;
    display: block;
    text-align: center;
    color: #595959;
  }
  &.not-running {
    border: 1px solid #595959;
    color: #595959;
    max-width: 65px;font-weight: 500;
    width: 100%;
    display: block;
    text-align: center;
    color: #595959;
  }

  &.in-used {
    border: 1px solid #00a65a;
    color: #00a65a;font-weight: 500;
    max-width: 65px;
    width: 100%;
    display: block;
    text-align: center;
    color: #00a65a;


  }

  &.rolled-out {
    border: 1px solid #E91E63;
    color: #E91E63;font-weight: 500;
    max-width: 65px;
    width: 100%;
    display: block;
    text-align: center;
    color: #E91E63;
  }

  &.never_used {
    border: 1px solid #595959;
    color: #595959;font-weight: 500;
    max-width: 65px;
    width: 100%;
    display: block;
    text-align: center;
  }

  &.disabled {
    border: 1px solid #ea3546;
    color: #ea3546;font-weight: 500;
    max-width: 65px;
    width: 100%;
    display: block;
    text-align: center;
  }

  &.view_report {
    border: 1px solid #ea3546;
    color: #ea3546;
    max-width: 91px;font-weight: 500;
    width: 100%;
    display: block;
    text-align: center;
  }

  &.view_report_available_soon {
    border: 1px solid #434343;
    color: white;
    max-width: 79px;
    background-color: #434343;
    width: 100%;
    display: block;font-weight: 500;
    text-align: center;
  }
}

.new-system-accordion {
  mat-expansion-panel.bg-grey {

    .mat-expansion-panel-content {
      .mat-expansion-panel-header.mat-expanded {
        height: var(--mat-expansion-header-expanded-state-height);
        background-color: #f3f3f3;
      }

    }

    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover {
      background: #9e9e9e !important;
      color: #ffffff !important;
    }

    .mat-expansion-panel-header:not([aria-disabled=true]) {
      cursor: pointer;
      background-color: #9e9e9e;
    }
  }

  .bg-grey.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
    background: #9e9e9e !important;
    color: #ffffff !important;
  }

  mat-expansion-panel.bg-purple {

    .mat-expansion-panel-content {
      .mat-expansion-panel-header.mat-expanded {
        height: var(--mat-expansion-header-expanded-state-height);
        background-color: #f3f3f3;
      }

    }

    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover {
      background: #635d9b !important;
      color: #ffffff !important;
    }

    .mat-expansion-panel-header:not([aria-disabled=true]) {
      cursor: pointer;
      background-color: #635d9b;
    }
  }

  .bg-purple.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
    background: #635d9b !important;
    color: #ffffff !important;
  }

  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    border-radius: 0px !important;
    margin: 0px;
  }

  .mat-expansion-indicator::after {
    color: white;
  }

  .mat-expansion-panel-header:not([aria-disabled=true]) {
    border-radius: 0px;
  }

  .mat-expansion-panel-header {
    padding: 0px 10px;
  }

  .mat-expansion-panel-body {
    padding: 0 10px 16px;
  }
}

// select section css

.bg-purple.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  cursor: pointer;
  background-color: #635d9b !important;
  border-radius: 0px;


}

.bg-grey.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  cursor: pointer;
  background-color: #9e9e9e !important;
  border-radius: 0px;


}

.bg-purple.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled),
.bg-grey.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) {

  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: #ffffff !important;
    font-weight: 600;
  }

  .mat-mdc-select {
    color: #ffffff !important;
  }

  .mat-mdc-select-arrow {
    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }
}

.selection-section {
  .new-system-accordion {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
      .mat-mdc-form-field-infix {
        display: flex;
        justify-content: center;
      }
    }
  }
}

.new-system-accordion.bg-purple {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      .mat-mdc-floating-label {
        left: 0px;
      }

    }
  }
}

.new-system-accordion.bg-grey {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-infix {
      .mat-mdc-floating-label {
        left: 0px;
      }

    }
  }
}

.filter-sidenav.associated-nav {
  .mat-drawer-backdrop {
    position: fixed !important;
  }
}

.custom-disabled-color {
  background-color: #dcdcdc !important;
  /* Your custom color for disabled state */
}


.flatpickr-calendar {
  top: 20% !important;
  right: 10% !important;
  left: auto !important;
}

.style-pass-strength-info {
  .mat-icon {
    -webkit-user-select: none;
    user-select: none;
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    margin-right: 12px;
    overflow: visible;
  }
}

.pass-info {
  .mat-mdc-card {
    padding: 0px !important;
  }
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
  padding-left: 12px;
  padding-right: 0;
  cursor: pointer;
}


.searchChip.mat-mdc-chip.mat-mdc-standard-chip:first-child {
  background-color: green !important;
  color: #ffffff;
  .mdc-evolution-chip__text-label {
    color: #ffffff;
  }
  .mat-mdc-chip-remove{
    color: #ffffff!important;font-weight: bold!important;
  }
}


.mdc-text-field__input{
  margin-top: 7px;
}
.mat-mdc-tab .mdc-tab__text-label{
  font-weight: bold;
}
.mat-mdc-header-cell{
background-color: #ECEAFF!important;
font-weight: bold;
font-size:15.5px ;
}
.mat-mdc-nav-list .mat-mdc-list-item:active{
  background-color: $body-color!important;
  a{
  background-color: $body-color!important;

  }
}
.mat-mdc-nav-list .mat-mdc-list-item:hover{
  background-color: $body-color;
}
.mat-mdc-nav-list .mat-mdc-list-item:routerlinkactive{
  background-color: $body-color!important;
}
// .mat-drawer .mat-sidenav {
//   background-color: $body-color;
// }
.mat-drawer-end {
  background-color: $body-color!important;
}
.mat-mdc-tooltip  {
  margin: 7px;
  // position: absolute;

  // &::after {
  //   width: 0;
  //   height: 0;
  //   content: '';
  //   position: absolute;
  //   border-left: 0.5rem solid transparent;
  //   border-right: 0.5rem solid transparent;
  //   border-bottom: 0.5rem solid #767676;
  // }
  // &.below {
  //   overflow: initial;
  //   margin-top: 1rem;
  //   &:after {
  //     top: -0.5rem;
  //     right: calc(50% - 0.5rem);
  //     transform: rotate(0);
  //   }
  // }

  // &.above {
  //   overflow: initial;
  //   margin-bottom: 1rem;
  //   &:after {
  //     bottom: -0.5rem;
  //     right: calc(50% - 0.5rem);
  //     transform: rotate(180deg);
  //   }
  // }

  // &.right {
  //   overflow: initial;
  //   margin-left: 1rem;
  //   &:after {
  //     left: -0.75rem;
  //     top: calc(50% - 0.25rem);
  //     transform: rotate(270deg);
  //   }
  // }

  // &.left {
  //   overflow: initial;
  //   margin-right: 1rem;
  //   &:after {
  //     right: -0.75rem;
  //     top: calc(50% - 0.25rem);
  //     transform: rotate(90deg);
  //   }
  // }
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: purple !important;
}
mat-icon.sort_applied svg path {
  fill: $button-primary-color!important;
}

mat-icon.sort-icon-pools svg{
  size: 16px!important;
  height: 16px!important;
  width: 16px!important;
  font-size: 16px!important;
  margin-top: 10px;

  height: 28px;
  width: 16px;
}
.sort-icon-pools {
  
  height: 28px!important;
  width: 16px!important;
}
.disabled_failed{
  cursor: not-allowed;
  opacity: 0.5;
}
.table-container {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
}

.table-container .custom-table {
  width: 100%;
  min-width: 1199px; /* Ensures table is not too small */
}
.update-system-tab {
  .section-header, .log-header-content{
    padding: 0px 20px;
  }
}
.connectivity-report-section .section-header .border-section {
  width: 86%!important;
}
@media (max-width: 1150px) {
  .connectivity-report-section .section-header .border-section {
    width: 80%!important;
  }
  .search-box input {
    font-size: 12px;
  }
  .task-btn{
    position: relative!important;
    right: 0px!important;
  }
}
@media (max-width: 1000px) {
  .search-box input {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
 
  .mat-header-cell,
  .mat-cell {
    padding: 8px; /* Reduce padding on smaller screens */
    font-size: 14px;
  }

  .table-container .custom-table {
    min-width: 1200px; /* Ensures it doesn’t shrink too much */
  }
}
.add-system-segment-custom-dialog .mat-radio-group-custom{
  flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
  .add-system-segment-custom-dialog{
    .login2{
      order: -1;
    }
    .login{
      margin: 0px 0px 10px!important;
      padding: 0px!important;
    }
  }
  .login-section{
    .segment-details{
      border-right: none;
    }
  }
}

@media (max-width: 600px) {
  .greyter-login{
    .mat-mdc-form-field {
      margin-bottom: 20px;
      .mat-mdc-form-field-error {
        padding-top: 10px;
        text-indent: -5px;
      }
    }
  }
  .list-container {
    padding: 20px 10px;
  }
  .new-dashboard-main .section-header .border-section{
    width: 80%!important;
  }
  .tabs-wrapper {
    padding: 20px 0px;
  }
  .search-box input {
    width: 98%;
    font-size: 11px;
  }
  .table-container .custom-table{
      min-width: 1150px;
  }
  .search-box {
    min-width: 340px;
    width: 100%;
    margin-left: 0px;
  }
  .task-btn{
    position: relative!important;
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    flex-wrap: wrap;
    right: 0px!important;
    gap: 10px;
    button{
      margin-right: 0px!important;
    }
  }
  .action-task-rollout-custom-dialog, 
  .delete-custom-dialog, 
  .delete-custom-dialog, 
  .suspenduser-custom-dialog, 
  .addproject-custom-dialog, 
  .newsystemversion-custom-dialog,
  .remotecommand-custom-dialog,
  .add-system-segment-custom-dialog,
  .import-custom-dialog,
  .addpool-custom-dialog,
  .exportdata-custom-dialog{
    max-width: 90vw!important;
    margin: 30px!important;
    .new-action-type2{
      order: -1;
      .content-space {
        padding: 0px 15px 30px 0px!important;
      }
    }
  }
  .newaction-custom-dialog, .new-system-version-section,  .add-system-segment-custom-dialog{
    .new-action-type2,.new-system-version2,.new-action2,.login2{
      order: -1;
      .content-space {
        padding: 0px 15px 30px 0px!important;
      }
      .center-space-border{
        padding: 0px 15px 15px 0px!important;
      }
    }
  }
  .new-system-version-section{
    .new-system-version2{
      padding: 0px!important;
    }
  }
  .remotecommand-custom-dialog, .newaction-custom-dialog, .new-system-version-section, .addproject-custom-dialog{
    .header-right-section{
      margin-bottom: 10px!important;
    }
    .action-button-group{
      flex-direction: column!important;
      gap: 10px!important;
      align-items: flex-start!important;
    }
  }
  .newaction-custom-dialog .new-action-type2 .content-space {
    padding: 25px 15px 0px 0px !important;
  }
  .newaction-custom-dialog .new-action-type-section .form-content {
    margin: 25px 0px 0px !important;
  }
  
  .add-system-segment-custom-dialog .form-content {
    margin: 15px 0px 0px !important;
  }
  .newaction-custom-dialog{
    max-width: 90vw!important;
    margin: 20px!important;
  }
  .add-project-custom-dialog, 
  .newsystemversion-custom-dialog,
  .newaction-custom-dialog{
    button.mdc-button--outlined{
      margin-right: 10px!important;
    }
  }
  .newaction-custom-dialog{
    button.mat-mdc-raised-button{
      min-width: 165px!important;
      width: 100%;
    }
  }
  .newaction-custom-dialog, .new-system-version-section, .addproject-custom-dialog, .remotecommand-custom-dialog{
    button.btn-button{
      min-width: 165px !important;
      width: 100%;
      margin-bottom: 10px!important;
    }
  }
  .addproject-custom-dialog .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label{
    max-width: 250px!important;
    overflow: hidden;
  }
  .action-button-group{
    button {
      margin-right: 16px !important;
    }
  }
}
.down-arrow{
  .mat-mdc-button-touch-target{
    width: 10px!important;
  }
}
@media (max-width: 450px) {
  .search-box{
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    input {
      width: 100%!important;
    }
  } 

}
@media (max-width: 380px) {
  .action-task-rollout-custom-dialog, 
  .delete-custom-dialog, 
  .delete-custom-dialog, 
  .suspenduser-custom-dialog, 
  .addproject-custom-dialog, 
  .newsystemversion-custom-dialog, 
  .remotecommand-custom-dialog, 
  .add-system-segment-custom-dialog, 
  .import-custom-dialog, 
  .addpool-custom-dialog, 
  .exportdata-custom-dialog {
    max-width: 90vw !important;
    margin: 18px !important;
  }
  .dashboard-topaction{
    position: relative!important;
  }
  .system-requirement-info.bar-section {
    max-width: 320px;
    width: 100%;
    max-width: 350px;
  }
  .add-system-segment-custom-dialog, .newsystemversion-custom-dialog{
    .dialog-top-bar{
      .mat-mdc-icon-button{
        width: 20px;
        padding-left: 0px;
        padding-right: 2px;
        margin-right: 5px;
      }
    } 
  }
  .search-box{
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    input {
      width: 100%!important;
    }
  } 
  .system-connectivity-capsule{
    min-width: 115px!important;
  }
}
